import { initializeApp } from "firebase/app";
import {
  getAuth,
  getRedirectResult,
  signInWithRedirect,
  GoogleAuthProvider,
  browserLocalPersistence,
  User,
} from "firebase/auth";
import { collection, getFirestore, query, where } from "firebase/firestore/lite";

// https://firebase.google.com/docs/web/setup#available-libraries

export const app = initializeApp({
  apiKey: "AIzaSyC48cs3Zl-b8ctXmA3A3lrYbdA_vAZ2F0Q",
  authDomain: "youbetcha-site.firebaseapp.com",
  projectId: "youbetcha-site",
  storageBucket: "youbetcha-site.appspot.com",
  messagingSenderId: "595064475504",
  appId: "1:595064475504:web:c529954a7cc57d6f96c9b5",
  measurementId: "G-295N6MXECT",
});

export const db = getFirestore(app);

export const betsCollection = collection(db, "bets");

export const eventsCollection = collection(db, "events");
export const queryEventsByOwner = (user: User) => query(eventsCollection, where("owner", "==", user.uid));

export const auth = getAuth();

export async function authorize() {
  await auth.setPersistence(browserLocalPersistence);

  const redirect = await getRedirectResult(auth);
  if (redirect) await auth.updateCurrentUser(redirect.user);

  return auth.currentUser;
}

export const signInGoogle = () => {
  const provider = new GoogleAuthProvider();
  signInWithRedirect(auth, provider);
};
