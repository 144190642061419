import { getDocs, Query } from "firebase/firestore/lite";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

async function fetchDocs<T>(query: Query, setDocs: Dispatch<SetStateAction<T[]>>) {
  try {
    const res = await getDocs(query);
    const docs = res.docs.map((doc) => doc.data()) as T[];
    setDocs(docs);
  } catch (e) {
    console.error(e);
    return [];
  }
}

export function useFirestoreDocs<T>(query: Query | null | undefined | false) {
  const [docs, setDocs] = useState<T[]>([]);

  useEffect(() => {
    if (query) fetchDocs<T>(query, setDocs);
  }, [query]);

  return docs;
}
