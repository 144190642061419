import { User } from "firebase/auth";
import { PropsWithChildren, createContext, useContext } from "react";

const UserContext = createContext<User>({} as User);

interface UserContextProviderProps extends PropsWithChildren {
  user: User;
}

export function UserContextProvider({ user, children }: UserContextProviderProps) {
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function useUser() {
  return useContext(UserContext);
}
