import { useUser } from "../hooks/use-user";
import { useFirestoreDocs } from "../hooks/use-firestore";
import { queryEventsByOwner } from "../app";

export function Home() {
  const user = useUser();
  const events = useFirestoreDocs(queryEventsByOwner(user));

  return (
    <>
      <div className="text-red-700">{user.email}</div>
      <pre>{JSON.stringify(events, null, 2)}</pre>
    </>
  );
}
