import "./main.css";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { authorize } from "./app";
import { Home } from "./pages/home";
import { Login } from "./pages/login";
import { UserContextProvider } from "./hooks/use-user";

const root = createRoot(document.getElementById("root") as HTMLDivElement);

async function render() {
  const user = await authorize();

  root.render(
    <StrictMode>
      {user ? (
        <UserContextProvider user={user}>
          <Home />
        </UserContextProvider>
      ) : (
        <Login />
      )}
    </StrictMode>
  );
}

render();
